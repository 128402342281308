<template>
  <div class="uav">
    <div class="list">
      <div class="item-title">
        <img class="icon" src="../assets/image/icon.png" alt="" />
        <span>农业机器数量</span>
      </div>
      <div class="stable-list">
        <div class="stable-item">
          <div class="stable-item-title">机器</div>
          <div class="stable-item-title">主数量</div>
          <div class="stable-item-title">子数量</div>
          <div class="stable-item-title">设备总量</div>
          <div class="stable-item-title">保修设备数量</div>
        </div>
        <div class="stable-item" v-for="item in workList" :key="item.work_id">
          <div class="one-txt-cut stable-item-title">{{ item.机器 }}</div>
          <div class="one-txt-cut item-status">{{ item.主数量 }}</div>
          <div class="one-txt-cut item-region">{{ item.子数量 }}</div>
          <div class="one-txt-cut item-time">{{ item.设备总量 }}</div>
          <div class="one-txt-cut item-time">{{ item.保修设备数量 }}</div>
        </div>
      </div>
    </div>
    <div class="right-box">
      <div class="top-box">
        <div class="task-box">
          <div class="item-title">
            <img class="icon" src="../assets/image/icon.png" alt="" />
            <span>巡检任务</span>
          </div>
          <div ref="charts-pie" class="charts-pie-box"></div>
        </div>
        <div class="monitor-list">
          <div class="item-title">
            <img class="icon" src="../assets/image/icon.png" alt="" />
            <span>监控查看</span>
          </div>
          <div class="monitor-box">
            <div class="monitor-item" v-for="(item, index) in 4" :key="index">
              <img src="../assets/image/jk_bg.png" alt="" />
            </div>
          </div>
        </div>
      </div>
      <div class="bottom-box">
        <div class="item-title">
          <img class="icon" src="../assets/image/icon.png" alt="" />
          <span>无人化农场作业管理进度</span>
        </div>
        <div ref="charts-row" class="charts-data-box"></div>
      </div>
    </div>
    <MyNav></MyNav>
  </div>
</template>

<script>
export default {
  components: {
    MyNav: () => import("@/components/MyNav.vue"),
  },
  data() {
    return {
      chartsRow: null,
      chartsPie: null,
      workList: [
        { 机器: "拖拉机", 主数量: 1, 子数量: 1, 设备总量: 1, 保修设备数量: 1 },
        { 机器: "拖拉机", 主数量: 1, 子数量: 1, 设备总量: 1, 保修设备数量: 1 },
        { 机器: "拖拉机", 主数量: 1, 子数量: 1, 设备总量: 1, 保修设备数量: 1 },
        { 机器: "拖拉机", 主数量: 1, 子数量: 1, 设备总量: 1, 保修设备数量: 1 },
        { 机器: "拖拉机", 主数量: 1, 子数量: 1, 设备总量: 1, 保修设备数量: 1 },
        { 机器: "拖拉机", 主数量: 1, 子数量: 1, 设备总量: 1, 保修设备数量: 1 },
        { 机器: "拖拉机", 主数量: 1, 子数量: 1, 设备总量: 1, 保修设备数量: 1 },
        { 机器: "拖拉机", 主数量: 1, 子数量: 1, 设备总量: 1, 保修设备数量: 1 },
        { 机器: "拖拉机", 主数量: 1, 子数量: 1, 设备总量: 1, 保修设备数量: 1 },
        { 机器: "拖拉机", 主数量: 1, 子数量: 1, 设备总量: 1, 保修设备数量: 1 },
        { 机器: "拖拉机", 主数量: 1, 子数量: 1, 设备总量: 1, 保修设备数量: 1 },
      ],
    };
  },
  mounted() {
    this.chartsRow = this.$echarts.init(this.$refs["charts-row"]);
    this.chartsPie = this.$echarts.init(this.$refs["charts-pie"]);
    this.initRowChart();
    this.initPieChart();
  },
  methods: {
    initPieChart() {
      let option = {
        grid: {
          top: "8%",
          bottom: "14%",
          left: "8%",
          right: "8%",
        },
        tooltip: {
          trigger: "item",
        },
        color: ["#E06666", "#4D6CFB"],
        // legend: {
        //   bottom: "5%",
        //   left: "center",
        // },
        series: [
          {
            type: "pie",
            radius: ["55%", "70%"],
            avoidLabelOverlap: false,
            label: {
              show: false,
              position: "center",
            },
            emphasis: {
              label: {
                show: true,
                fontSize: 20,
                fontWeight: "bold",
              },
            },
            labelLine: {
              show: false,
            },
            data: [
              { value: 48, name: "巡检任务1" },
              { value: 35, name: "巡检任务2" },
              { value: 58, name: "巡检任务3" },
              { value: 44, name: "巡检任务4" },
              { value: 30, name: "巡检任务5" },
            ],
          },
        ],
      };
      this.chartsPie.setOption(option);
    },
    initRowChart() {
      let option = {
        grid: {
          top: "8%",
          bottom: "14%",
          left: "8%",
          right: "8%",
        },
        xAxis: {
          max: "dataMax",
          axisLabel: {
            show: false,
          },
          splitLine: {
            lineStyle: {
              color: "transparent",
            },
          },
        },
        yAxis: {
          type: "category",
          data: ["项目一", "项目二", "项目三", "项目四", "项目五"],
          inverse: true,
          axisLabel: {
            color: "#fff",
          },
          splitLine: {
            lineStyle: {
              color: "transparent",
            },
          },
        },
        dataZoom: [
          {
            type: "inside",
          },
        ],
        series: [
          {
            barWidth: 18,
            itemStyle: {
              color: {
                type: "linearGradient",
                x: 1,
                y: 1,
                x2: 0,
                y2: 0,
                colorStops: [
                  {
                    offset: 0,
                    color: "rgba(48, 236, 238, 1)",
                  },
                  {
                    offset: 1,
                    color: "rgba(0, 109, 154, 1)",
                  },
                ],
              },
            },
            realtimeSort: true,
            type: "bar",
            data: [
              1, 6, 3, 2, 3,
              // {
              //   value: 6,
              //   itemStyle: {
              //     color: {
              //       type: "linearGradient",
              //       x: 1,
              //       y: 1,
              //       x2: 0,
              //       y2: 0,
              //       colorStops: [
              //         {
              //           offset: 0,
              //           color: "rgba(251, 184, 42, 1)",
              //         },
              //         {
              //           offset: 1,
              //           color: "rgba(250, 184, 42, 0)",
              //         },
              //       ],
              //     },
              //   },
              // },
            ],
            label: {
              show: true,
            },
          },
        ],
      };
      this.chartsRow.setOption(option);
    },
  },
};
</script>

<style lang="less" scoped>
.uav {
  width: 100%;
  height: 100vh;
  background-color: #071a2c;
  padding: 80px 38px 0;
  display: flex;
  justify-content: space-between;
  .item-title {
    width: 100%;
    color: #fff;
    font-size: 18px;
    display: flex;
    align-items: center;
    padding: 0 20px;
    margin-bottom: 10px;
    .icon {
      width: 12px;
      margin-right: 10px;
    }
  }
  .list {
    width: 45%;
    height: 97%;
    background: radial-gradient(
      circle,
      rgba(27, 91, 159, 0.09) 0%,
      rgba(20, 46, 76, 0.59) 100%
    );
    border-radius: 10px;
    border: 1px solid #3e93e6;
    padding: 22px 0;
    .stable-list {
      width: 100%;
      height: 97%;
      overflow-y: auto;
      padding: 0 5px;
      &::-webkit-scrollbar {
        width: 2px;
      }
      &::-webkit-scrollbar-thumb {
        border-radius: 10px;
        background-color: #3e90e5;
      }
      .stable-item {
        width: 100%;
        height: 60px;
        padding: 0 28px;
        display: flex;
        justify-content: space-around;
        align-items: center;
        &:first-of-type {
          height: 40px;
          background-color: rgba(62, 105, 190, 0.6) !important;
        }
        &:nth-of-type(odd) {
          background-color: rgba(29, 64, 132, 1);
        }
        div {
          height: 60px;
          text-align: center;
          line-height: 60px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          font-size: 16px;
          color: #fff;
          &:nth-of-type(1) {
            width: 180px;
          }
          &:nth-of-type(2) {
            width: 80px;
          }
          &:nth-of-type(3) {
            width: 120px;
          }
          &:nth-of-type(4) {
            width: 120px;
          }
          &:nth-of-type(5) {
            width: 180px;
          }
        }
      }
    }
  }
  .right-box {
    width: 54%;
    height: 97%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .top-box {
      width: 100%;
      height: 49%;
      display: flex;
      justify-content: space-between;
      .task-box {
        height: 100%;
        width: 49%;
        border-radius: 10px;
        border: 1px solid #3e93e6;
        padding: 22px 0;
        .charts-pie-box {
          width: 100%;
          height: 90%;
        }
      }
      .monitor-list {
        height: 100%;
        width: 49%;
        border-radius: 10px;
        border: 1px solid #3e93e6;
        padding: 22px 0 0 0;
        .monitor-box {
          width: 100%;
          height: 90%;
          display: flex;
          flex-wrap: wrap;
          justify-content: space-around;
          padding: 0 10px;
          .monitor-item {
            width: 45%;
            height: 45%;
            border: 1px solid #fff;
            img {
              width: 100%;
              height: 100%;
            }
          }
        }
      }
    }
    .bottom-box {
      width: 100%;
      height: 49%;
      border-radius: 10px;
      border: 1px solid #3e93e6;
      padding: 30px;
      .charts-data-box {
        width: 100%;
        height: 100%;
      }
    }
  }
}
</style>
